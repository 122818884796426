import { PICTURES_ENDPOINT } from "constants/paths";
import React from "react";
import { StyledAvatar } from "./index.style";

/** The props of the `Avatar` component */
export interface AvatarProps {
  accountId?: number;
  gravatarEmail?: string;
  name?: string;
  sensitive?: boolean;
  size?:
    | "detailsText"
    | "xs"
    | "small"
    | "regular"
    | "medium"
    | "large"
    | "big"
    | "xl";
}

const Avatar = (props: AvatarProps) => {
  const sizes = {
    detailsText: 14,
    xs: 24,
    small: 32,
    regular: 40,
    medium: 48,
    large: 64,
    big: 80,
    xl: 100,
  };

  const size = props.size !== undefined ? sizes[props.size] : 50;

  let imageSize = size * 2; // We double the size for retina display
  if (imageSize < 200) {
    imageSize = 200;
  } else if (imageSize < 400) {
    imageSize = 400;
  } else {
    imageSize = 512;
  }

  const baseAccountUrl = `${PICTURES_ENDPOINT}/accounts/${props.accountId}/${imageSize}/light`;

  return (
    <>
      {props.accountId && (
        <StyledAvatar
          size={size}
          width={size}
          height={size}
          alt="avatar"
          src={baseAccountUrl}
        />
      )}
    </>
  );
};

export default Avatar;
