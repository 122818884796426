import styled from "styled-components";

interface AvatarProps {
  size: number;
}

const StyledAvatar = styled.img<AvatarProps>`
  border-radius: 50% !important;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: inline-block;
`;

export { StyledAvatar };
