export enum SignupTemplateType {
  Email = "email",
  Survey = "survey",
  Trigger = "trigger",
  CannedResponse = "canned-response",
}

export interface Author {
  account_id: number;
  public_name: string;
  username: string;
  bio: string;
  website: string;
}

export interface EmailTemplate {
  description: string;
  community_email_template_id: number;
  name: string;
  subject: string;
  thumbnail_url: string;
  created_at: number;
  author: Author;
}

export interface EmailRendering {
  html: string;
  json: string;
}

export interface CannedResponseTemplate {
  canned_response_template_id: number;
  emoji: string;
  description: string;
  background: string;
  canned_response: {
    is_shared: boolean;
    shortcut: string;
    text: string;
    title: string;
  };
  author: Author;
}

export enum SurveyQuestionType {
  Button = 0,
  Radio = 1,
  Select = 2,
  Scale = 3,
  Star = 4,
  Integer = 5,
  Textbox = 6,
  Textarea = 7,
}

export interface SurveyQuestionChoice {
  value: string;
  goto_step: number;
}

export interface SurveyQuestion {
  title: string;
  subtitle?: string;
  default_goto_step?: number;
  type: SurveyQuestionType;
  choices?: SurveyQuestionChoice[];
  step: number;
}

export interface SurveyTemplate {
  background: string;
  description: string;
  emoji: string;
  survey_template_id: number;
  name: string;
  questions: SurveyQuestion[];
  survey: {
    name: string;
    thankyou_text: string;
  };
  author: Author;
}

export interface Action {
  contents: {
    content: {
      content: string;
    };
  }[];
}

export interface ChatTemplate {
  background: string;
  trigger_template_id: number;
  emoji: string;
  description: string;
  name: string;
  chat: {
    actions: Action[];
  };
  author: Author;
}

export interface TemplatesEmailsQueryResult {
  allEmailsTemplates: {
    nodes: EmailTemplate[];
  };
}

export interface TemplatesSurveysQueryResult {
  allSurveysTemplates: {
    nodes: SurveyTemplate[];
  };
}

export interface TemplatesCannedResponsesQueryResult {
  allCannedResponsesTemplates: {
    nodes: CannedResponseTemplate[];
  };
}

export interface CommunityAuthorsQueryResult {
  allCommunityAuthors: {
    nodes: Author[];
  };
}

export interface Chatflow {
  id: number;
  name: string;
  description: string;
  uri: string;
  image: string;
}
