import styled from "styled-components";
import { Col, media } from "styled-bootstrap-grid";

interface TemplateBackgroundProps {
  show: boolean;
}

const TemplateContainer = styled(Col)`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;

  ${media.md`
    padding: 64px 64px 64px 0;
  `}
`;

const TemplateBackground = styled.div<TemplateBackgroundProps>`
  background: rgb(249 249 249);
  min-height: 640px;
  border-radius: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 64px 0;

  ${media.xs`
    min-height: auto;
  `}

  ${(props) =>
    !props.show &&
    `
     display: none;
    `}
`;

const MessageContainer = styled.div`
  display: flex;
  gap: 16px;
  max-width: 360px;
  user-select: none;
`;

const MessageContentInnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.accentLighten80Color};
  color: ${(props) => props.theme.titleColor};
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  word-break: break-word;
  background: ${(props) => props.theme.accentLighten80Color};
  border-radius: 8px 0 8px 8px;

  p {
    font-size: 14px;
    line-height: 24px;
  }

  a {
    font-size: 14px;
    line-height: 24px;
  }
`;

export {
  TemplateContainer,
  TemplateBackground,
  MessageContainer,
  MessageContentInnerContainer,
};
