import {
  CannedResponseTemplate as CannedResponseTemplateType,
  SignupTemplateType,
} from "app-types/template";
import GlobalWrapper from "components/core/GlobalWrapper";
import React from "react";
import SEO from "components/core/SEO";
import { CANNED_RESPONSE_TEMPLATES_PATH } from "constants/paths";
import { useTranslation } from "react-i18next";
import {
  MessageContainer,
  MessageContentInnerContainer,
  TemplateBackground,
  TemplateContainer,
} from "templates/templates/canned-response/index.style";
import Avatar from "components/ui/base/Avatar";
import { HeaderContainer, PageContainer } from "styles/global.style";
import { Row } from "styled-bootstrap-grid";
import { DescriptionCol } from "components/ui/base/ImageHeader/index.style";
import Fade from "components/core/Animation/extended/Fade";
import Breadcrumbs from "components/core/Breadcrumbs";
import Heading from "components/core/Typography/Heading";
import SafeLink from "components/core/SafeLink";
import Button from "components/core/Button";
import CommunityAuthorBox from "components/ui/extended/CommunityAuthorBox";
import NumberSteps from "components/ui/base/NumberSteps";
import VideoPreview from "components/ui/base/VideoPreview";
import ReadPost from "components/ui/base/ReadPost";
import Customers from "components/ui/extended/Customers";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import { getTemplateSignupUrl } from "utils/path";
import { CTAColor } from "app-types/color";
import BlogPostsBox from "components/ui/base/BlogPostsBox";

export interface CannedResponseTemplateProps {
  pageContext: {
    template: CannedResponseTemplateType;
  };
  location: Location;
}

const CannedResponseTemplate = ({
  pageContext,
  location,
}: CannedResponseTemplateProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper
      withLayout
      layoutSettings={{ withCta: false }}
      location={location}
    >
      <SEO
        title={pageContext.template.canned_response.title}
        description={pageContext.template.description}
        image={{
          relativePath: "meta/community-templates-for-saas.jpg",
          alt: pageContext.template.canned_response.title,
        }}
        withoutTitleSuffix
      />

      <HeaderContainer>
        <PageContainer>
          <Row>
            <TemplateContainer lg={7} order={1} lgOrder={1}>
              <TemplateBackground show>
                <MessageContainer>
                  <MessageContentInnerContainer
                    dangerouslySetInnerHTML={{
                      __html: pageContext.template.canned_response.text,
                    }}
                  />
                  <Avatar size={"medium"} accountId={3} />
                </MessageContainer>
              </TemplateBackground>
            </TemplateContainer>
            <DescriptionCol lg={5} order={2} lgOrder={2}>
              <Fade direction={"up"}>
                <Breadcrumbs
                  elements={[
                    {
                      name: "Customerly",
                      path: "/",
                    },
                    {
                      name: "Templates",
                      path: "/templates/",
                    },
                    {
                      name: "Canned Responses",
                      path: `${CANNED_RESPONSE_TEMPLATES_PATH}/`,
                    },
                  ]}
                />
              </Fade>

              <Fade direction={"up"}>
                <Heading level={1}>
                  {pageContext.template.canned_response.title}
                </Heading>
              </Fade>

              <Fade direction={"up"} delay={500}>
                <p>{pageContext.template.description}</p>
              </Fade>

              <Fade direction={"up"} delay={1000}>
                <Button
                  as={SafeLink}
                  to={getTemplateSignupUrl(
                    SignupTemplateType.CannedResponse,
                    pageContext.template.canned_response_template_id
                  )}
                  ctaColor={CTAColor.Yellow}
                  icon={{ type: "chevron-right", size: 16 }}
                >
                  {t("templates.templates.canned-response.useTemplate")}
                </Button>
              </Fade>
              <Fade direction={"up"} delay={1200}>
                <CommunityAuthorBox author={pageContext.template.author} />
              </Fade>
            </DescriptionCol>
          </Row>
        </PageContainer>
      </HeaderContainer>

      <NumberSteps
        title={t("templates.templates.canned-response.steps.title")}
        elements={Array.from(Array(3).keys()).map((i) => {
          return {
            title: t(
              `templates.templates.canned-response.steps.elements.${
                i + 1
              }.title`
            ),
            description: t(
              `templates.templates.canned-response.steps.elements.${
                i + 1
              }.description`
            ),
          };
        })}
      />

      <VideoPreview
        id={"use-canned-responses"}
        title={t("templates.templates.canned-response.1.title")}
        paragraph={t("templates.templates.canned-response.1.subtitle")}
        storiesPosition={"right"}
        stories={[
          {
            relativePath:
              "templates/templates/canned-response/use-canned-responses.mp4",
          },
        ]}
      />
      <ReadPost slug={"the-best-canned-response-templates-for-live-chat"} />

      <VideoPreview
        id={"use-canned-responses"}
        title={t("templates.templates.canned-response.2.title")}
        paragraph={t("templates.templates.canned-response.2.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath:
              "templates/templates/canned-response/share-canned-response.mp4",
          },
        ]}
      />

      <RegisterForCustomerService />

      <Customers />

      <BlogPostsBox
        category={"customer-service"}
        title={"Customer Service Articles"}
        maxPosts={3}
        postsPerRow={3}
        currentPostSlug={"/"}
      />
    </GlobalWrapper>
  );
};

export default CannedResponseTemplate;
